import Head from "next/head";
import { useRouter } from "next/router";

import ButtonNew from "components/atoms/ButtonNew/index";
import Img from "components/atoms/Img";
import Title from "components/atoms/Title";
import Layout from "components/common/Layout";
import Cookies from "components/common/Layout/Cookies";

import styles from "./Index.module.scss";

const Custom404 = () => {
  const router = useRouter();
  const locale = router.asPath.split("/");

  const subFolder404 = () => {
    return (
      <Layout locationRouter={locale[1]}>
        <div className={`${styles.spacingTop}`}></div>
        <section className={`${styles.img404} relative `}>
          <Img
            src="/assets/images/404/background-404.png"
            alt="404 bg"
            width={1920}
            height={867}
            className="mx-auto"
            wrapper={{
              className:
                "hidden lg:flex items-center w-full h-full absolute top-0 left-0",
            }}
          />
          <div className="container relative z-10 text-center">
            <div className="row justify-center">
              <div className="lg:col-8">
                <Img
                  src="/assets/images/404/image-404.png"
                  alt="404 image"
                  width={762}
                  height={287}
                />
                <Title text="Page Not Found" className={`${styles.title404}`} />
                <p className="mt-4 tracking-[0.25px]">
                  The page you were looking for doesn&apos;t exist.
                  <br />
                  Visit the Homepage or Contact us about the problem.
                </p>
                <ButtonNew as="a" href="/" othersClass="mt-8 lg:mt-4">
                  Home Page
                </ButtonNew>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  };

  const normal404 = () => {
    return (
      <>
        <Cookies />
        <section className={`${styles.normal404} relative `}>
          <Img
            src="/assets/images/404/background-404.png"
            alt="404 bg"
            width={1920}
            height={867}
            className="mx-auto"
            wrapper={{
              className:
                "hidden lg:flex items-center w-full h-full absolute top-0 left-0",
            }}
          />
          <div className="container relative z-10 text-center">
            <div className="row justify-center">
              <div className="lg:col-8">
                <Img
                  src="/assets/images/404/image-404.png"
                  alt="404 image"
                  width={762}
                  height={287}
                />
                <Title text="Page Not Found" className={`${styles.title404}`} />
                <p className="mt-4 tracking-[0.25px]">
                  The page you were looking for doesn&apos;t exist.
                  <br />
                  Visit the Homepage or Contact us about the problem.
                </p>
                <a
                  href="/hk"
                  className="transition-background mt-8 inline-block rounded-full bg-bbc-brand-500 py-2 px-6 font-semibold text-white duration-150 hover:bg-bbc-brand-700 hover:text-white lg:mt-4"
                >
                  Home Page
                </a>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  };

  const renderTemplate = () => {
    if (
      locale[1] !== "hk" &&
      locale[1] !== "sg" &&
      locale[1] !== "vn" &&
      locale[1] !== "offshore"
    ) {
      return normal404();
    }

    return subFolder404();
  };

  return (
    <>
      <Head>
        <title>Page not found | BBCIncorp Limited</title>
      </Head>
      {renderTemplate()}
    </>
  );
};

export default Custom404;
